 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="600"
        @hide="$emit('hide')"
        class="canned-response-info"
    >
        <div slot="header">
            <strong class="f-size-18">Canned response template syntaxis</strong>
        </div>
        <div slot="body">
            <div v-if="Object.keys(value).length" class="pb-8 pt-6">
                <div class="color-grey">You can include following commands in order to customize your template:</div>

                <div v-for="item, key, i in value" :key="i" class="mt-8">
                    <div v-for="_item, _key, _i in value[key]" :key="_i" class="flex">
                        <strong class="canned-response-info__name w__50-p pr-2 cursor-pointer" @click="copy(toName(_item, _key))">{{ toName(_item, _key) }}</strong>
                        <span class="w__50-p text-ellipsis" :title="toDescription(key, _key)"> {{ toDescription(key, _key) }}</span>
                    </div>
                </div>
            </div>

            <div v-else class="pb-8 pt-6 color-grey">
                First select "Template applicability"
            </div>
        </div>

        <div slot="footer" class="w__100-p border__top-grey-1 pt-6 pl-6 pr-6">
            <div class="flex justify-end w-full">

                <button
                    @click="$emit('hide')"
                    class="px-8 btn-grey-outline"
                >
                    Close
                </button>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    name: 'CannedResponseInfo',

    props: {
        value: {
            type: Object,
            default: () => { return {} },
        },
    },

    methods: {
        toName(value, key) {
            if (!key) {
                return '';
            }

            if (value) {
                return `~~~$[${key}]$~~~`;
            }

            return `$[${key}]$`;
        },

        toDescription(parentKey, key) {
            const parent = parentKey.split('_').join(' ');
            const firstLetter = parent[0].toUpperCase();
            const value = key.split('.').join(' ');

            return `${firstLetter}${parent.slice(1).toLowerCase()} ${value}`;
        },

        copy(value) {
            try {
                navigator.clipboard.writeText(value);

                this.$snotify.success(`Text copied`);
            } catch(error) {
                this.$snotify.success(`Error copy text`);
            }
        },
    }
}
</script>

<style lang="scss">
.canned-response-info {
    .card-header {
        margin-bottom: 0;
    }
    
    .modal__footer {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .first-letter-uppercase::first-letter {
        text-transform: uppercase !important;
    }
}
</style>
